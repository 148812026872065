import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const InvestmentCalculator = () => {
  // State for input values
  const [monthlyPayment, setMonthlyPayment] = useState(25000);
  const [totalMonths, setTotalMonths] = useState(31);
  const [finalAmount, setFinalAmount] = useState(1000000);

  // Calculate cumulative payments and monthly profits
  const getCumulativePayments = () => {
    const payments = [];
    let cumulative = 0;
    for (let i = 1; i <= totalMonths; i++) {
      cumulative += monthlyPayment;
      payments.push(cumulative);
    }
    return payments;
  };

  // Calculate monthly profit for each month
  const getMonthlyProfits = () => {
    const profits = [];
    const cumulativePayments = getCumulativePayments();
    for (let i = 1; i <= totalMonths; i++) {
      const expectedAmount = (finalAmount / totalMonths) * i;
      const profit = expectedAmount - cumulativePayments[i - 1];
      profits.push(profit > 0 ? profit : 0); // Ensure profits cannot be negative
    }
    return profits;
  };

  const cumulativePayments = getCumulativePayments();
  const monthlyProfits = getMonthlyProfits();

  // Chart Data
  const chartData = {
    labels: Array.from({ length: totalMonths }, (_, i) => `Month ${i + 1}`),
    datasets: [
      {
        label: 'Cumulative Payments (₹)',
        data: cumulativePayments,
        borderColor: '#3b82f6',
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        tension: 0.4,
        fill: true,
      },
      {
        label: 'Monthly Profit (₹)',
        data: monthlyProfits,
        borderColor: '#10b981',
        backgroundColor: 'rgba(16, 185, 129, 0.5)',
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `₹${tooltipItem.raw.toLocaleString()}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `₹${value.toLocaleString()}`,
        },
      },
    },
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-xl mt-8">
      <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">
        Investment Calculator
      </h1>

      <div className="space-y-4">
        {/* Input Fields */}
        <div className="flex flex-col">
          <label className="mb-2 text-gray-600">Monthly Payment</label>
          <input
            type="number"
            value={monthlyPayment}
            onChange={(e) => setMonthlyPayment(Number(e.target.value))}
            className="border p-2 rounded focus:ring-2 focus:ring-blue-500"
            placeholder="Enter monthly payment"
          />
        </div>

        <div className="flex flex-col">
          <label className="mb-2 text-gray-600">Total Months</label>
          <input
            type="number"
            value={totalMonths}
            onChange={(e) => setTotalMonths(Number(e.target.value))}
            className="border p-2 rounded focus:ring-2 focus:ring-blue-500"
            placeholder="Enter total months"
          />
        </div>

        <div className="flex flex-col">
          <label className="mb-2 text-gray-600">Final Amount</label>
          <input
            type="number"
            value={finalAmount}
            onChange={(e) => setFinalAmount(Number(e.target.value))}
            className="border p-2 rounded focus:ring-2 focus:ring-blue-500"
            placeholder="Enter final amount"
          />
        </div>

        {/* Results Section */}
        <div className="bg-gray-100 p-4 rounded-lg mt-6">
          <div className="flex justify-between mb-2">
            <span className="text-gray-700">Total Payments:</span>
            <span className="font-semibold">
              ₹{(monthlyPayment * totalMonths).toLocaleString()}
            </span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="text-gray-700">Profit:</span>
            <span className="font-semibold text-green-600">
              ₹{(finalAmount - monthlyPayment * totalMonths).toLocaleString()}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-700">Profit Percentage:</span>
            <span className="font-semibold">
              {(((finalAmount - monthlyPayment * totalMonths) / (monthlyPayment * totalMonths)) * 100).toFixed(2)}%
            </span>
          </div>
        </div>

        {/* Chart Section */}
        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            Paid Amount & Monthly Profits Over Time
          </h2>
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default InvestmentCalculator;
