import React from 'react';
import InvestmentCalculatorForm from './components/InvestmentCalculatorForm';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        {/* <h1>Investment Calculator</h1> */}
      </header>
      <main>
        <InvestmentCalculatorForm />
      </main>
    </div>
  );
}

export default App;
